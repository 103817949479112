// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const MUTATION = gql`
  mutation ApplyDossierWorkflowTransition(
    $dossierId: ID!,
    $dossierTypeSlug: String!,
    $navigationSlug: String!,
    $workflowId: ID!,
    $transitionName: String!
  ) {
    dossierWorkflowTransitionDossier(
      id: $dossierId,
      dossierTypeSlug: $dossierTypeSlug,
      navigationSlug: $navigationSlug,
      workflowId: $workflowId,
      transitionName: $transitionName
    ) {
      dossierTypeStatus {
        label
        description
      }
      metadata
    }
  }
`;

export default MUTATION;
